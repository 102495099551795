import { customerService } from '@/api';
import { Paging } from '@/api/base';
import { OsTable } from '@/components';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTableOption, OsTableColumn } from '@/components/os-table/os-table';
import { DialogMixin } from '@/mixins/dialog';
import { CustomerSelect } from '@/resource/model';
import { dateFormat, messageError } from '@/utils';
import { ElTable } from 'element-ui/types/table';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class SelectCustomer extends mixins(DialogMixin) {
  @Prop({ type: Number, required: true })
  public userId!: number;

  public pageTotal = 0;

  public tableOption: OsTableOption<CustomerSelect> = {
    height: '400px',
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<CustomerSelect>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'companyName',
      label: 'customer.name',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '100px',
      formatter: (row: object): string => {
        return dateFormat((row as CustomerSelect).createTime);
      }
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'companyName',
      label: 'customer.name',
      option: {
        placeholder: 'common.inputKeyWord'
      },
      colSpan: 8
    }
  ];

  public selectedRows: Array<CustomerSelect> = [];

  public queryForm: Partial<{
    companyName: string;
    userId: number;
  }> = {};

  private paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public dialogOpen(): void {
    this.loadData();
  }

  public dialogClosed(): void {
    this.clearSelection();
  }

  public queryClick(): void {
    this.paging.currentPage = 1;
    this.clearSelection();
    this.loadData();
  }

  public handleSelectionChange(selectedData: Array<CustomerSelect>): void {
    this.selectedRows = [...selectedData];
  }

  public get confirmDisabled(): boolean {
    return !(this.selectedRows.length > 0);
  }

  public get selectedNumTemplate(): string {
    return `(${this.selectedRows.length}/${this.pageTotal})`;
  }

  public onSubmit(): void {
    this.$emit('confirm', this.selectedRows);
    this.closeDialog();
  }

  /**
   * 清空选中状态
   */
  public clearSelection(): void {
    ((this.$refs.customerTable as OsTable).tableRef as ElTable).clearSelection();
  }

  private loadData(): void {
    this.tableOption.loading = true;
    this.queryForm.userId = this.userId;
    customerService
      .getCustomerByUser(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data || [];
        this.pageTotal = res.total || 0;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }
}
