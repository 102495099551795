import { CustomerSelect, PersonnelSelect, SaveCustomerTransfer } from '@/resource/model';
import { Component, Vue } from 'vue-property-decorator';
import SelectPersonnel from '@/views/dialogs/select-personnel/select-personnel.vue';
import SelectCustomer from './select-customer/select-customer.vue';
import { customerService } from '@/api';
import { messageError, showWarningConfirm, translation } from '@/utils';
import { Message } from 'element-ui';
type SelectUserType = 'source' | 'target';

@Component({
  components: { SelectPersonnel, SelectCustomer }
})
export default class CustomerTransfer extends Vue {
  public selectedCustomers: Array<CustomerSelect> = [];

  public selectedTargetUser: Array<PersonnelSelect> = [];

  /**
   * 控制选择用户弹框
   */
  public selectUserVisible = false;

  /**
   * 控制选择客户弹框
   */
  public selectCustomerVisible = false;

  public sourceUserId = 0;

  public selectedUserName = '';

  /**
   * 当前用户选择框选择的用户类型
   */
  public currentSelectUserType: SelectUserType | '' = '';

  public get canSubmit(): boolean {
    return this.selectedCustomers.length > 0 && this.selectedTargetUser.length === 1;
  }

  public openUserDialog(type: SelectUserType): void {
    this.currentSelectUserType = type;
    this.selectUserVisible = true;
  }

  public openCustomerDialog(): void {
    this.selectCustomerVisible = true;
  }

  public deleteCustomer(customer: CustomerSelect): void {
    this.selectedCustomers = this.selectedCustomers.filter(x => x.id !== customer.id);
  }

  public deleteTargetUser(user: PersonnelSelect): void {
    this.selectedTargetUser = this.selectedTargetUser.filter(x => x.id !== user.id);
  }

  public handleUserConfirm(user: PersonnelSelect): void {
    if (this.currentSelectUserType === 'source') {
      this.sourceUserId = user.id;
      this.selectedUserName = user.realName;
      this.selectCustomerVisible = true;
    } else {
      this.selectedTargetUser = [user];
    }
  }

  public handleCustomerConfirm(customers: Array<CustomerSelect>): void {
    this.selectedCustomers = customers;
  }

  public async submit(): Promise<void> {
    try {
      await showWarningConfirm(translation('customerTransfer.confirmTip'));
      const data: SaveCustomerTransfer = {
        sourceUserId: this.sourceUserId,
        targetUserId: this.selectedTargetUser[0]?.id,
        targetUserName: this.selectedTargetUser[0]?.realName,
        customerIdList: this.selectedCustomers.map(x => x.id)
      };
      await customerService.customerTransfer(data);
      this.resetData();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      if (error !== 'cancel') {
        messageError(error);
      }
    }
  }

  private resetData(): void {
    this.selectedCustomers = [];
    this.selectedTargetUser = [];
    this.selectedUserName = '';
    this.currentSelectUserType = '';
    this.sourceUserId = 0;
  }
}
